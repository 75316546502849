@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: '#74767a';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


  .Toastify__toast--default {
    background: #4791db
  }
  .Toastify__toast--info {
    background: #64b5f6;
  }
  .Toastify__toast--success {
    background: #81c784;
  }
  .Toastify__toast--warning {
    background: #ffb74d;
  }
  .Toastify__toast--error {
    background: #e57373
  }
  
.basic {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:0cm;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}

.basic2 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:0cm;
    line-height:normal;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}

.basic3 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:0cm;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
}

.basic4 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:.0001pt;
    margin-left:36.0pt;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}

.basic5 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:36.0pt;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}
