
  .Toastify__toast--default {
    background: #4791db
  }
  .Toastify__toast--info {
    background: #64b5f6;
  }
  .Toastify__toast--success {
    background: #81c784;
  }
  .Toastify__toast--warning {
    background: #ffb74d;
  }
  .Toastify__toast--error {
    background: #e57373
  }
  