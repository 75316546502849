.basic {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:0cm;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}

.basic2 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:0cm;
    line-height:normal;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}

.basic3 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:0cm;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
}

.basic4 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:.0001pt;
    margin-left:36.0pt;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}

.basic5 {
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:8.0pt;
    margin-left:36.0pt;
    line-height:107%;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    text-align:justify;
}